import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

function HeroImage({ data }) {
  if (data.heroImage.gatsbyImageData === null) {
    return (
      <img
        src={data.heroImage.file.url}
        alt={data.heroImage.title}
        className="object-fit-cover absolute-fill"
      />
    );
  }

  const image = getImage(data.heroImage);

  return (
    <GatsbyImage
      image={image}
      alt={data.heroImage.title}
      style={{ position: 'absolute' }}
      loading="eager"
      layout="fullWidth"
      className="object-fit-cover absolute-fill"
    />
  );
}

export default HeroImage;
