import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BaseLayout from '../layouts/BaseLayout';
import HeroBlock from '../components/HeroBlock/HeroBlock';
import NewsBlock from '../components/NewsBlock/NewsBlock';
import LanguageContext from '../context/Language';
import { createScript } from '../utils/videoSetup';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      en: contentfulHomepage(
        contentful_id: { eq: "2FWalb2YJvnsguTG02ow5T" }
        node_locale: { eq: "en-GB" }
      ) {
        pageBlocks {
          __typename
          ...HeroBlockFragment
        }
        showLatestNews
        title
        latestNewsTitle
        latestNewsButtonLabel
        seoDescription
      }
      nl: contentfulHomepage(
        contentful_id: { eq: "2FWalb2YJvnsguTG02ow5T" }
        node_locale: { eq: "nl-NL" }
      ) {
        pageBlocks {
          __typename
          ...HeroBlockFragment
        }
        showLatestNews
        title
        latestNewsTitle
        latestNewsButtonLabel
        seoDescription
      }
    }
  `);
  const context = useContext(LanguageContext);
  const localData = data[context.lang];
  useEffect(() => {
    const [script1, script2, script3, customStyle] = createScript('1_4ars39fs');
    return () => {
      script1.remove();
      script2.remove();
      script3.remove();
      customStyle.remove();
    };
  }, []);
  return (
    <BaseLayout title={localData.title} description={localData.seoDescription}>
      <>
        {localData.pageBlocks.map((block, index) => {
          return (
            <HeroBlock key={block.id} data={block} index={index}></HeroBlock>
          );
        })}
        {localData.showLatestNews && (
          <NewsBlock
            latestNewsTitle={localData.latestNewsTitle}
            latestNewsButtonLabel={localData.latestNewsButtonLabel}
          />
        )}
      </>
    </BaseLayout>
  );
};

export default IndexPage;
