import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { graphql, Link } from 'gatsby';
import renderRichTextContent from '../../utils/renderRichText';
import HeroImage from './HeroImage';
import HeroIllustration from './HeroIllustration';
import isReady from '../../utils/isReady';
import VideoIllustration from "../IllustrationBlock/VideoIllustration";


function HeroBlock({ data,index }) {
  const showScrim = () => (data.useOverlayScrim ? 'overlay-scrim-x' : '');
  const bgType = () =>
    data.backgroundTone ? 'bg-dark bg-almost-dark' : 'bg-light';
  const textAlignment = () => {
    if (data.illustration) {
      return '';
    }
    return data.textBlockAlignment ? '' : 'offset-lg-6';
  };
  const conditionalStyles = () => {
    if (!(index === 0)) return [];
    return [
      { display: 'contents' },
      {
        background:
          'linear-gradient(90deg,hsla(0,0%,7%,.925) 0,hsla(0,0%,7%,.575))',
        opacity: '0.8',
        paddingLeft: '18px',
        paddingBottom: '1px',
      },
      { paddingTop: '14px' },
    ];
  };

  const [first = null, second = null, third = null] = conditionalStyles();
  const imageAlignment = () => (data.textBlockAlignment ? '' : 'order-first');

  return (
    <section
      className={`section-padding-xl d-flex align-items-center ${bgType()} overlay ${showScrim()}`}
      style={{
        minheight: '800px',
        height: '50%',
        maxHeight: '1000px',
        boxSizing: 'border-box',
      }}
    >
      {(data.heroImage || data.heroImage !== null) && <HeroImage data={data} />}
      <Container>
        <Row style={first}>
          <Col md="8" lg="6" className={textAlignment()} style={second}>
            <h1 className="display-3 mb-4" style={third}>{data.title}</h1>
            <div className={`lead font-family-headings opacity-90 lh-1-75 ${
                index === 0 ? '' : 'mb-7'
              }`}>
              {renderRichTextContent(data.body)}
            </div>
            {data.callToActionPage && (
              <Link
                to={data.callToActionPage.slug}
                className="btn btn-primary btn-xl rounded-pill d-block d-sm-inline-block mr-sm-3 mb-3"
              >
                {data.callToAction}
              </Link>
            )}
          </Col>          
          {data?.kalturaId && (
            <Col md="8" lg="6" className={imageAlignment()}>
              <VideoIllustration entryId={data.kalturaId} />
            </Col>
          )}
          {isReady(data.illustration) && (
            <Col md="8" lg="6" className={imageAlignment()}>
              <HeroIllustration data={data} />
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default HeroBlock;

export const query = graphql`
  fragment HeroBlockFragment on ContentfulHeroBlock {
    id
    callToAction
    heroImage {
      gatsbyImageData(placeholder: TRACED_SVG, height: 800, width: 3000)
      file {
        url
      }
      title
    }
    illustration {
      gatsbyImageData(
        layout: FIXED
        placeholder: BLURRED
        quality: 100
        resizingBehavior: SCALE
        width: 550
      )
      file {
        url
      }
      title
    }
    title
    callToActionPage {
      slug
    }
    body {
      raw
    }
    backgroundTone
    useOverlayScrim
    textBlockAlignment
    kalturaId
  }
`;
